.quickshortcut {
  display: inline-block;
  background-color: #ffffff;
  border-radius: 10px;
  border: 2px solid #ffffff;
  transition: all 0.3s !important;
  width: 100%;
}

.quickshortcut h4,
.quickshortcut p {
  transition: all 0.3s !important;
}

.quickshortcut:hover {
  border: 2px solid #e7e7e7;
}

/* .quickshortcut:hover h4,
.quickshortcut:hover p {
  color: rgb(0, 69, 113) !important;
} */
