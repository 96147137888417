.container-scroll::-webkit-scrollbar {
    display: none;
}

.container-scroll ::-moz-scrollbar {
    display: none;
}

.container-scroll {
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-color: transparent transparent; /*just hides the scrollbar for firefox */
}